@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Roboto-Bold.ttf");
}