@import "~@/styles/fonts";
@import "~@/styles/variables";

html,
body {
  color: $blackLight;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  color: $blue;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

$headings-margin: 30px, 20px, 20px, 20px, 20px, 20px;

@for $i from 1 through length($headings-margin) {
  $margin: nth($headings-margin, $i);

  body .MuiTypography-h#{$i} {
    margin-bottom: $margin;
  }
}
